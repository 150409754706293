<template>
  <div class="settings-account-employees">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Index',
}
</script>

<style lang="scss" scoped>
.settings-account-employees {
}
</style>
